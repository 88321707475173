<template>
  <!-- Modal -->
  <div class="modal" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de permisos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Usuario (*):</label>
                <select id="usu_id" v-model="item.usu_id" class="form-control" style="width: 100%;" required>
                  <option v-for="usuario in usuarios" v-bind:value="usuario.id">
                    {{ usuario.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Dependencia (*):</label>
                <select id="dep_id" v-model="item.dep_id" class="form-control" style="width: 100%;" required>
                  <option v-for="dependencia in dependencias" v-bind:value="dependencia.id">
                    {{ dependencia.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="col-md-4 col-form-label">Serie (*):</label>
                <select id="ser_id" v-model="item.ser_id" class="form-control" style="width: 100%;" required>
                  <option v-for="serie in series" v-bind:value="serie.id">
                    {{ serie.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label class="col-md-4 col-form-label">Clase de documento (*):</label>
                <select id="tcr_id" v-model="item.tcr_id" class="form-control" style="width: 100%;" required>
                  <option v-for="tpocor in tpocors" v-bind:value="tpocor.id">
                    {{ tpocor.text }}
                  </option>
                </select>        
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label>Ver (*):</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_ver">
              </div>
              <div class="form-group col-md-3">
                <label>Modificar (*):</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_mdfcr">
              </div>
              <div class="form-group col-md-3">
                <label>Imprimir (*):</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_imprmr">
              </div>
              <div class="form-group col-md-3">
                <label>Modificar otros usuarios (*):</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_mdfcr_otros">
              </div>
              <div class="form-group col-md-3">
                <label>Archivar (*):</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.uxr_archvr">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="search()">Buscar permisos</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css';

//import Select2 from '../select2/Select2.vue';

export default {
  //name: 'SearchUsuxrad',
  components: {
    //Select2
  },
  props: ['item'],
  data() {
    return {
      usuarios: [],
      dependencias: [],
      series: [],
      tpocors: []
    }
  },
  mounted: function() {
    $(document).ready(function() {
      $('select').select2({
        dropdownParent: $('#searchModal')
      });
            
    });
  },
  created: function() {
    this.fetchUsuario();
    this.fetchDependencia();
    this.fetchSerie();
    this.fetchTpocor();
  },
  destroyed: function () {
    $(this.$el).find('select').select2('destroy');
  },
  methods: {
    fetchUsuario(){
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
          var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));
      });
    },
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.dep_nmbre + ' (' + a.dep_cdgo + ')').toLowerCase(); 
          var tb = String(b.dep_nmbre + ' (' + b.dep_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.dependencias = list.map(i => ({ id: String(i._id), text: i.dep_nmbre + ' (' + i.dep_cdgo + ')' }));
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.ser_nmbre + ' (' + a.ser_cdgo + ')').toLowerCase(); 
          var tb = String(b.ser_nmbre + ' (' + b.ser_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.series = list.map(i => ({ id: String(i._id), text: i.ser_nmbre + ' (' + i.ser_cdgo + ')' }));
      });
    },
    fetchTpocor()
    {
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tcr_nmbre + ' (' + a.tcr_cdgo + ')').toLowerCase(); 
          var tb = String(b.tcr_nmbre + ' (' + b.tcr_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpocors = list.map(i => ({ id: String(i._id), text: i.tcr_nmbre + ' (' + i.tcr_cdgo + ')' }));
      });
    },
    search(){
      if ($('#usu_id').val())
        this.item.usu_id = $('#usu_id').val();
      if ($('#dep_id').val())
        this.item.dep_id = $('#dep_id').val();
      if ($('#ser_id').val())
        this.item.ser_id = $('#ser_id').val();
      if ($('#tcr_id').val())
        this.item.tcr_id = $('#tcr_id').val();

      this.$emit('search', this.item);      
      $(this.$el).find('select').val(null).trigger('change');

    }
  } // END METHODS
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/

</style>
