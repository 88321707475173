<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Usuarios por Dependencia y Serie</h1>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <div v-if="filter">
              <button v-on:click="closeSearch" class="btn btn-link">
                <i data-feather="arrow-left-circle"></i>Cerrar búsqueda
              </button>
            </div>
            <div>
              <form v-on:submit.prevent="searchItems({ keys: keywords })">
                <div class>
                  <button
                    type="button"
                    v-on:click="newSearch()"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#searchModal"
                  >Búsqueda avanzada</button>
                </div>
                <searchusuxrad v-on:search="filtrarItems" v-bind:item="search"></searchusuxrad>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateUsuxrad', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Usuario por serie</button>
              <button v-on:click="CopyItems()" class="btn btn-primary"><i data-feather="copy"></i>Copiar permisos de un usuario</button>
              <!--
              <button v-on:click="CreateFromDep()" class="btn btn-primary"><i data-feather="copy"></i>Crear permisos desde Dependencia</button>
              -->
            </div>
            <br>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="list-doc"
                role="tabpanel"
                aria-labelledby="list-doc-list"
              >
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Usuario</td>
                        <td>Cód. Dependencia</td>
                        <td>Dependencia</td>
                        <td>Cód. Serie</td>
                        <td>Serie</td>
                        <td>Clase Documento</td>
                        <td>Ver</td>
                        <td>Modificar</td>
                        <td>Imprimir</td>
                        <td>Modificar otros</td>
                        <td>Archivar</td>
                        <td class="text-center">
                          <input type="checkbox" v-model="allChecked" v-on:click="checkAllItems" />
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in items">
                        <td class="text-center">
                            <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                        </td>
                        <td>{{ item.usu_nmbre }}</td>
                        <td>{{ item.dep_cdgo }}</td>
                        <td>{{ item.dep_nmbre }}</td>
                        <td>{{ item.ser_cdgo }}</td>
                        <td>{{ item.ser_nmbre }}</td>
                        <td>{{ item.tcr_nmbre }}</td>
                        <td>{{ item.uxr_ver | formatBoolean }}</td>
                        <td>{{ item.uxr_mdfcr | formatBoolean }}</td>
                        <td>{{ item.uxr_imprmr | formatBoolean }}</td>
                        <td>{{ item.uxr_mdfcr_otros | formatBoolean }}</td>
                        <td>{{ item.uxr_archvr | formatBoolean }}</td>
                        <td class="text-center">
                          <input type="checkbox" v-bind:value="item._id" v-model="delItems" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="list-doc-archivo"
                role="tabpanel"
                aria-labelledby="list-archivo-list"
              >...</div>
            </div>
            <br />
            <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
              <i data-feather="delete"></i>Eliminar
            </button>
            <button
              type="button"
              id="customXLSButton"
              class="btn btn-primary"
              v-on:click="exportReport"
            >Exportar</button>
            <!--{{delItems}}-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatBoolean } from './../../plugins/filters';
import SearchUsuxrad from "./SearchUsuxrad.vue";
import { EditIcon } from "vue-feather-icons";
import moment from "moment";
import XLSX from "xlsx";

export default {
  components: {
    searchusuxrad: SearchUsuxrad,
    EditIcon
  },
  data() {
    return {
      items: [],
      usuarios: [],
      dependencias: [],
      series: [],
      tpocors: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords: "",
      showProgress: false,
      search: {},
      filter: false // Items are filtered
    };
  },
  filters: {
    formatBoolean
  },
  computed: {
    checkedItems() {
      if (this.delItems.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function() {
    this.fetchStart();
  },
  methods: {
    fetchStart(){
      this.fetchUsuario();
    },
    fetchUsuario() {
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then(response => {
        this.usuarios = response.data;

        this.fetchDependencia();
      });
    },
    fetchDependencia() {
      let uri = "/deps/" + String(this.$store.state.company);
      this.axios.get(uri).then(response => {
        this.dependencias = response.data;

        this.fetchSerie();
      });
    },
    fetchSerie() {
      let uri = "/series/" + String(this.$store.state.company);
      this.axios.get(uri).then(response => {
        this.series = response.data;

        this.fetchTpocor();
      });
    },
    fetchTpocor() {
      let uri = "/tpocors/" + String(this.$store.state.company);
      this.axios.get(uri).then(response => {
        this.tpocors = response.data;

        if (this.$route.params.search != undefined) {
          this.search = this.$route.params.search;
          this.searchItems(this.search);
        }        
      });
    },
    findUsuario(id){
      for (var j = 0; j < this.usuarios.length; j++) {
        if (id == this.usuarios[j]._id) {
          return this.usuarios[j].usu_nmbre + " " + this.usuarios[j].usu_aplldo;
        }
      }
    },
    deleteItem(id, index) {
      const response = confirm("Está seguro que desea eliminar?");
      if (response) {
        let uri = "/uxr/delete/" + id;
        this.axios
          .get(uri)
          .then(res => {
            this.items.splice(index, 1);
          })
          .catch(err => console.log(err));
      }
      return;
    },
    deleteItems() {
      if (this.delItems.length > 0) {
        const response = confirm(
          "Está seguro que desea eliminar los registros seleccionados?"
        );
        if (response) {
          for (var i = 0; i < this.delItems.length; i++) {
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = "/uxr/delete/" + this.delItems[i];
            this.axios
              .get(uri)
              .then(res => {
                //this.items.splice(index, 1);
                if (this.search) {
                  this.searchItems(this.search);
                }
              })
              .catch(err => {
                console.log(err);
                for (var m = 0; m < this.items.length; m++) {
                  if (this.delItems[i] == this.items[m]._id) {
                    alert("No se ha podido eliminar el item " + this.items[m]);
                    m = this.items.length;
                  }
                }
              });
          }
        }
      }
      return;
    },
    checkAllItems() {
      this.delItems = [];
      if (!this.allChecked) {
        for (var i = 0; i < this.items.length; i++) {
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    newSearch() {
      this.search = {};
    },
    filtrarItems(p){
      this.searchItems(p);
    },
    searchItems(p) {
      //debugger;
      if (p != null) {
        this.showProgress = true;
        this.debug = p;

        p.cmp_id = String(this.$store.state.company);
        this.message = "Buscando permisos";
        let uri = "/uxr/search";
        this.axios
          .post(uri, p)
          .then(response => {
            this.items = [];
            
            var list = response.data;
            //this.search = p;
            this.showProgress = false;
            this.delItems = [];

            const start = (list, i) => {
              //list[i].usu_nmbre = this.findUsuario(list[i].usu_id);
              for (var j = 0; j < this.usuarios.length; j++) {
                if (list[i].usu_id  == this.usuarios[j]._id) {
                  list[i].usu_nmbre = this.usuarios[j].usu_nmbre + " " + this.usuarios[j].usu_aplldo;
                  j = this.usuarios.length;
                }
              }

              for (var j = 0; j < this.dependencias.length; j++) {
                if (list[i].dep_id == this.dependencias[j]._id) {
                  list[i].dep_cdgo = this.dependencias[j].dep_cdgo;
                  list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                  j = this.dependencias.length;
                }
              }

              for (var j = 0; j < this.series.length; j++) {
                if (list[i].ser_id == this.series[j]._id) {
                  list[i].ser_cdgo = this.series[j].ser_cdgo;
                  list[i].ser_nmbre = this.series[j].ser_nmbre;
                  j = this.series.length;
                }
              }

              for (var j = 0; j < this.tpocors.length; j++) {
                if (list[i].tcr_id == this.tpocors[j]._id) {
                  list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                  j = this.tpocors.length;
                }
              }

              i++;
              if (i < list.length){
                start(list, i);
              } else {
                this.items = list;
              }
            }

            var i = 0;
            start(list, i);            
          })
          .catch(err => {
            this.showProgress = false;
            this.message = "¡Error al buscar los documentos" + err;
          });
      }
    },
    closeSearch() {
      this.filter = false;
      this.keywords = "";
    },
    createItem() {
      this.$router.push({ name: "CreateUsuxrad", params: { add: true } });
    },
    editItem(id) {
      this.$router.push({
        name: "CreateUsuxrad",
        params: { id: id, add: false, search: this.search }
      });
    },
    CopyItems() {
      this.$router.push({
        name: "CopyUsuxrad",
        params: { search: this.search }
      });
    },
    CreateFromDep() {
      this.$router.push({
        name: "CreateFromDep",
        params: { search: this.search }
      });
    },
    exportReport() {
      var tableId = "tablaUsuariosDependenciaSerie";

      var report = [];
      for (var i = 0; i < this.items.length; i++) {
        let item = {};
        item.usu_nmbre = this.items[i].usu_nmbre;
        item.dep_cdgo = this.items[i].dep_cdgo;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_cdgo = this.items[i].ser_cdgo;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.tcr_nmbre = this.items[i].tcr_nmbre;
        item.uxr_ver = this.items[i].uxr_ver ? 'Si' : 'No';
        item.uxr_mdfcr = this.items[i].uxr_mdfcr ? 'Si' : 'No';
        item.uxr_imprmr = this.items[i].uxr_imprmr ? 'Si' : 'No';
        item.uxr_mdfcr_otros = this.items[i].uxr_mdfcr_otros ? 'Si' : 'No';
        item.uxr_archvr = this.items[i].uxr_archvr ? 'Si' : 'No';

        report.push(item);
      }

      var fecha = moment(new Date()).format("DD/MMM/YYYY hh:mm A");

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.$store.state.companydata.cmp_nmbre],
        [fecha],
        [],
        ["Permisos de Usuarios por Dependencia y Serie"],
        [],
        [
          "Usuario",
          "Cód. Dependencia",
          "Dependencia",
          "Cód. Serie",
          "Serie",
          "Tipo Documento",
          "Ver",
          "Modificar",
          "Imprimir",
          "Modificar otros",
          "Archivar"
        ]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, {
        header: [],
        skipHeader: true,
        origin: "A7"
      });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + ".xlsx"); // name of the file is 'book.xlsx'
    }
  } //END METHODS
};
</script>